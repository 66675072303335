import React, {useState} from 'react'
import PropTypes from 'prop-types'

export default function TexthtmlForm(props) {
  const [text, setText] = useState("");
  
  const handleCapCase = () => {
    let word = text.split(' ');
    for(let i = 0; i < word.length; i++) {
        word[i] = word[i][0].toUpperCase() + word[i].substring(1).toLowerCase();
    }
    setText(word.join(' '));
    props.showAlert("Text converted to captilize", "success")
  }

  return (
    <div>
        <div className="mb-3">
            <label htmlFor="myBox" className="form-label">{props.heading}</label>
            <textarea className="form-control" id="myBox" rows="8" value={text} onChange={(e) => setText(e.target.value)}></textarea>
        </div>
        <button className="btn btn-primary mx-2" onClick={() => setText(text.toUpperCase())}>Uppercase</button>
        <button className="btn btn-secondary mx-2" onClick={() => setText(text.toLowerCase())}>Lowercase</button>
        <button className="btn btn-success mx-2" onClick={handleCapCase}>Capitalize</button>
    </div>    
  )
}
TexthtmlForm.propTypes = {
    heading: PropTypes.string.isRequired
}
TexthtmlForm.defaultProps = {
    heading: "Default"
}
import './App.css';
import About from './components/About';
import Alert from './components/Alert';
import Navbar from './components/Navbar';
import TextForm from './components/TextForm';
import React, {useState} from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function App() {

  const [mode, setMode] = useState('light');
  const toggleMode = () => {
    if (mode === 'light') {
      setMode('dark');
      document.body.style.backgroundColor = 'grey'
      showAlert("Dark Mode has been enabled", "success")
    } else {
      setMode('light')
      document.body.style.backgroundColor = 'white'
      showAlert("Light Mode has been enabled", "success")
    }
  }

  const [alert, setAlert] = useState(null);
  const showAlert = (message, type) => {
      setAlert({
          msg: message,
          type: type
      })
      setTimeout(() => {
        setAlert(null)
      }, 2000)
  }

  return (
    <Router>
        <div>
          <Navbar title="TextUtils" mode={mode} toggleMode={toggleMode}/>
          <Alert alert={alert}/>
          <div className='container my-3'>
            <Routes>
              <Route path='/' element={<TextForm heading="Enter the text to analyze below" showAlert={showAlert}/>}/>
              <Route path='/about' element={<About/>}/>      
            </Routes>            
          </div>
        </div>
    </Router>
  );
}

export default App;
